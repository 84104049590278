import React, { useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import styled from 'styled-components'
import { memoizeWith, prop } from 'ramda'
import { mediaQuery } from 'utils/style'
import Container from 'components/Container'



const StyledLink = styled.div`
  font-size: 22px;
  position: relative;
  font-weight: bold;
  flex: 1;
  display: flex;
  align-items: flex-end;
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.base.blue};
  color: ${({ theme }) => theme.colors.base.blue};
  padding-right: 25px;
  :after {
    content: ' ';
    position: absolute;
    height: 20px;
    width: 20px;
    border-color: ${({ theme }) => theme.colors.base.orange};
    border-style: solid;
    border-top-width: 2px;
    border-left: 0;
    border-bottom: 0;
    border-right-width: 2px;
    left: 100%;
    bottom: 0;
    transform: translate(-150%, -50%) rotate(45deg);
    transition: all 0.3s ease-in-out;
  }
`

const useStyles = makeStyles(theme => ({
  panel: {
    backgroundColor: 'inherit',
    width: 'calc(50% - 1rem)',
    display: 'inline-block',
    margin: ({margin}) => `0.5rem 0.5rem ${margin}rem 0.5rem !important`, // NOTE: !important rule required for mui component
    boxShadow: 'none',
    '&::before': {
      height: 0,
      opacity: 0,
    },
    "& .MuiCollapse-container": {
      position: "absolute",
    },
    // TODOJ: ensure MUI breakpoints match utils/theme.js
    [theme.breakpoints.down(1080)]: {
      width: 'calc(100% - 1rem)',
      "& .MuiCollapse-container": {
        position: "relative",
      },
    },
    [`&.Mui-expanded .link-container ${StyledLink}::after`]: {
      transform: 'translate(-150%, -50%) rotate(135deg)'
    },
  },
  summary: {
    height: '100%',
    minHeight: 'unset',
    '&.Mui-expanded':{
      minHeight: 'unset'
    },
    padding: 0,
    '&.Mui-expanded .MuiExpansionPanelSummary-content': {
      margin: '12px 0',
    },
  },
}))

const gtmPush = memoizeWith(prop('id'), download => () => {
  dataLayer.push({ event: 'download', downloadSlug: download.slug })
})

const SectionTitle = styled.h4`
  margin-left: 1rem;
  font-size: 2rem;
  font-weight: 400;
  color: black;
`

// TODOJ: fix this styling between tablet (1080) and mobile (480)
const SectionWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  ${mediaQuery()({
    max: 'flex-direction: row;',
    desktop: 'flex-direction: row;',
    tablet: 'flex-direction: column;',
    mobile: 'flex-direction: column;',
  })}
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.base.orange};
`

const ExpansionPanelContent = styled(ExpansionPanelDetails)`
  display: flex;
  flex-direction: column;
`

const LinkContainer = styled.div`
  flex-wrap: wrap;
  flex: 1;
  display: flex;
  padding: 0 12px;
`

// TODOJ: Add functionality to select/link downloads via keywords, NOT via checkboxes
export default function StoryblokDownloadGroup({ data, ...props }) {
  const { downloads, title } = data
  

  // Sort the downloads by type
  const groupedDownloads = useMemo(
    () => {
      if (!downloads) return
      return downloads.reduce((acc, dl) => {
        const { type } = dl?.component || {}
        if (!type) return acc
        return {
          ...acc,
          [type]: acc[type] ? [...acc[type], dl] : [dl],
        }
      }, {})
    },
    [downloads]
  )

  const margin=Object.values(groupedDownloads).reduce((acc,val)=>Math.max(acc,val.length*1.5),0)

  const classes = useStyles({margin})

  return (
    <Container extraWide {...props}>
      {title && <SectionTitle>{title}</SectionTitle>}
      <SectionWrapper>
        {groupedDownloads &&
          Object.keys(groupedDownloads).map(groupName => (
            <ExpansionPanel className={classes.panel}>
              <ExpansionPanelSummary className={classes.summary}>
                <LinkContainer className="link-container">
                  <StyledLink>{groupName || 'Downloads'}</StyledLink>
                </LinkContainer>
              </ExpansionPanelSummary>
              <ExpansionPanelContent>
                {groupedDownloads[groupName].map(download => (
                  <Label>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={download.component?.file?.localFile?.publicURL}
                      onClick={gtmPush(download)}
                    >
                      {download.component?.name}
                    </a>
                  </Label>
                ))}
              </ExpansionPanelContent>
            </ExpansionPanel>
          ))}
      </SectionWrapper>
    </Container>
  )
}
